export const navigation = [
  {
    text: 'Справочник',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Пищеблок',
    icon: 'folder',
    items: [
      {
        text: 'Анализ меню',
        icon: 'datatrending',
        path: '/profile'
      },

    ]
  },
  {
  text: 'Регион/район',
  icon: 'folder',
  items: [
    {
      text: 'Отчёты',
      icon: 'movetofolder',
      path: '/upload'
    },

  ]
}
  
  
  ];
