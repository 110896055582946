import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Lookup } from 'devextreme-react/lookup';
import './profile.scss';
import TextBox from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { Popover } from 'devextreme-react/popover';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages, date} from 'devextreme/localization';
import DataGrid, {Column,DataGridTypes,Pager,Paging,Search,ColumnChooser,Export,HeaderFilter,LoadPanel} from 'devextreme-react/data-grid';
import { SelectBox} from 'devextreme-react/select-box';
import {RangeSelector, Margin, Scale, MinorTick, SliderMarker, Color} from 'devextreme-react/range-selector';
import Moment from 'moment';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import DataSource from 'devextreme/data/data_source';
import { DateBox } from 'devextreme-react/date-box';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from "axios";
import Scheduler, { View } from 'devextreme-react/scheduler';
import { Calendar } from 'devextreme-react/calendar';
import {TabPanel,Item} from 'devextreme-react/tab-panel';



export default function Profile() {
  
  const aLongTimeAgo=Moment().add(-14,'days').format('YYYY,MM,DD');
  const current = new Date();
  const date = Moment().add(-14,'days').format('YYYY,MM,DD');
  const dateend = Moment().format('YYYY,MM,DD');
  const cookies = new Cookies();

  const [status, SetStatus] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    const baseURL = "https://api.cemon.ru/status";
    axios.post(baseURL).then((response) => {

      const term = searchParams.get("id");
      if (term>0){
        SetStatus(true);

        setSelectpisheblok(term);
      }
      getListregion();
    }).catch(error => {
      SetStatus(false);
    });

  }, []);






  //Задание временной шкалы
  const [startValue, setstartValue] = useState(new Date(2024, 8, 1));
  const [endValue, setendValue] = useState(new Date(2025, 7, 31));
  
  //Задание промежутка
  const [range, setrange] = useState([date, dateend]);
  const [selectstart, setselectstart] = useState(date);
  const [selectend, setselectend] = useState(dateend);

  const [god, setgod] = useState(2025);
  const getgod = [
    {value: 2022, label: '2021-2022'},
    {value: 2023, label: '2022-2023'},
    {value: 2024, label: '2023-2024'},
    {value: 2025, label: '2024-2025'},   
  ];

  const setinggod = useCallback(({ value }) => { 
    


    setgod(value);
    setstartValue(new Date(value-1, 8, 1));
    setendValue(new Date(value, 4, 31));
    setrange([new Date(String(value), 0, 15), new Date(String(value), 0, 21)]);
    
    
    
  }, []);
  

 

  //Руссификация сообщений
  locale(sessionStorage.getItem('locale') || 'ru');
  loadMessages(ruMessages);
  
//Загрузка списка регионов
const [listregion, setListRegion] = useState();
const getListregion = async (id) => {
  const response = await fetch(
    "https://api.cemon.ru/listregion"
  ).then((response) => response.json());
  setListRegion(response);
  
};

    //Запрос пищеблоков по id региона
  const [pisheblok, setUsers] = useState();
  const getPisheblok = async (id) => {
    const response = await fetch(
      "https://api.cemon.ru/listpisheblok/?id="+id
    ).then((response) => response.json());
    setUsers(response);
    
  };

  const groupedData = useMemo(
    () => new DataSource({
      store: pisheblok,
      key: 'value',
      group: 'rayon',
    })
    ,[pisheblok]
  );


 






  const ChangeRegion = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
    setSelectregion(e.value);
    getPisheblok(e.value);

  }, []);




  const ChangePisheblok = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
    cookies.set('id', e.value, { path: '/' });
    setSelectpisheblok(e.value);
    setlinkdata('https://api.cemon.ru/pishinfo/?id='+e.value);

  }, []);










  
//Значение для полей поиска региона/пищеблока
        const [selectpisheblok, setSelectpisheblok] = useState();
        const [selectregion, setSelectregion] = useState();

  const valueChangedidpoisk = useCallback(({ value }) => {
    setiddlapoiska(value);
  }, []);

  const [iddlapoiska, setiddlapoiska] = useState();
  const [linkdata, setlinkdata] = useState();
  const [menudata, setmenudata] = useState();
  const [menuinfo, setmenuinfo] = useState();


  const [popupVisible, setpopupVisible] = useState(false);

  const [menuVisible, setmenuVisible] = useState(false);
  const [menutitle, setmenutitle] = useState();
  const [menudataw, setmenudataw] = useState();

  const [menuVisiblesab, setmenuVisiblesab] = useState(false);
  const [menutitlesab, setmenutitlesab] = useState();
  const [menudatawsab, setmenudatawsab] = useState();
  const [menudatawsabb, setmenudatawsabb] = useState();
  const [menudatawsabbb, setmenudatawsabbb] = useState();

  const [linkdataerror, setlinkdataerror] = useState();
  const [chtenielog, setchtenielog] = useState();
  const [ocenki, setocenki] = useState();
  const [sobludenietip, setsobludenietip] = useState();
  const [findex, setfindex] = useState();
  const [inn, setinn] = useState();
  const [coordina,setcoordina] = useState();


  let [searchParams, setSearchParams] = useSearchParams();
  

const PoiskPoid = () => {  
  const  response = fetch(
    "https://api.cemon.ru/pisheblokpoid/?id="+iddlapoiska
  ).then(response => response.json())
  .then(data => { 
    setSelectpisheblok(data[0].value);
    setSelectregion(data[0].RegionID);
    setiddlapoiska('');
  })
  .catch(error => {console.error(error); setpopupVisible(true)});
}

const PoiskPoidiscook = (id) => {  
  const  response = fetch(
    "https://api.cemon.ru/pisheblokpoid/?id="+id
  ).then(response => response.json())
  .then(data => { 
    setSelectpisheblok(data[0].value);
    setSelectregion(data[0].RegionID);
    setiddlapoiska('');
  })
  .catch(error => {console.error(error); setpopupVisible(true)});
}



const hideInfo = useCallback(() => {
  setpopupVisible(false);
}, []);


const [workingDaysCount, setSelectedEmployees] = useState();

const selectPeriod = useCallback((e: RangeSelector.ValueChangedEvent) => {
  setrange(e.value);
  setselectstart(Moment(e.value[0]).format("YYYY,MM,DD"));
  setselectend(Moment(e.value[1]).format("YYYY,MM,DD"));
}, []);

const selectPeriodn = (e: RangeSelector.ValueChangedEvent) => {
  const copyState = [...range];
  copyState[0] = Moment(e.value).format("YYYY,MM,DD");
  setrange(copyState);
  setselectstart(Moment(e.value).format("YYYY,MM,DD"));
  console.log(range);
};

const selectPeriodk = (e: RangeSelector.ValueChangedEvent) => {
  const copyState2 = [...range];
  copyState2[1] = Moment(e.value).format("YYYY,MM,DD");
  setrange(copyState2);
  setselectend(Moment(e.value).format("YYYY,MM,DD"));
};










useEffect(() => {

  console.clear();


  setmenudata('https://api.cemon.ru/menudata?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
  setlinkdataerror('https://api.cemon.ru/provarkamenu?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
  setocenki('https://api.cemon.ru/ocenki?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
  setchtenielog('https://api.cemon.ru/logsread?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
  setsobludenietip('https://api.cemon.ru/sopostovlenie?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
  setfindex('https://api.cemon.ru/findex?id='+selectpisheblok);
  getinn();
  getmenuinfo(selectpisheblok,selectstart,selectend)
  getlistzapros(selectpisheblok);
  getpin(selectpisheblok);
  getguid(selectpisheblok);
  setpinpinkode();
  setlistpr('https://api.cemon.ru/perezaprosmenu/proverka/?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend);
    if (cookies.get('id')>0){
      PoiskPoidiscook(cookies.get('id'));
    }


}, [selectstart,selectend,selectpisheblok])


//Ссылка для адреса папки food
const editlink = (va,vab)=>{
if (va=='Сайт'){
    return(
      <div><a href={vab} target='_blank'>{vab}</a></div>
    )}
    else
    {
      return(
        <div>{vab}</div>
      )}
    }





//-----------Блок настройки столбцов-------------------------
    //Раздел
    const otbrrazdel = (va)=>{
      if (va==''){
          return(
            <div><b>не указан</b></div>
          )}
          else
          {
            return(
              <div>{va}</div>
            )}
          }

//------------[Проверка выхода блюда]-------------------------




          const otbrvihod = (va,vb)=>{
            const error=<div className='examplethree'>{vb}</div>;

            if (va==='гор.блюдо'&& vb<150){return (error)}
            else if (va==='2 блюдо'&& vb<90) {return (error)}
            else if (va==='1 блюдо'&& vb<200) {return (error)}
            else if (va==='гарнир'&& vb<150) {return (error)}
            else if (va==='закуска'&& vb<60) {return (error)}
            else if (va==='фрукты'&& vb<100) {return (error)}
            else {return (<div>{vb}</div>)}
          }

//---------------[Не разбиты ли приёмы пищи на отдельные строчки]------------
const proverkavihoda = ()=>{

}


const linkCell = (cellData: DataGridTypes.ColumnCellTemplateData) => (
  

 <div>{editlink(cellData.data.Показатель,cellData.data.Значение)}</div>)



const VihodCell = (cellData: DataGridTypes.ColumnCellTemplateData) => ( 
    <div>
    
    {otbrvihod(cellData.data.Раздел, cellData.data.Выход)}
    </div>
)

const ChartRazdel = (cellData: DataGridTypes.ColumnCellTemplateData) => ( 
  <div>
  
  {otbrrazdel(cellData.data.Раздел)}
  </div>
)

const bludoCell = (cellData: DataGridTypes.ColumnCellTemplateData) => ( 
  <div>
  
  {otbrrazdel(cellData.data.Блюдо)}
  </div>
)


//Замена запятых
const pattern = /,/g;

//Функция экспорта
const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Выгрузка');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
    });
  });
};


const clickmenu = (values)=>{
  setmenutitle(values.data['Дата']);
  setmenuVisible(true);
  const toDate = (dateStr) => {
    const [day, month, year] = dateStr.split(".")
    return new Date(year, month - 1, day)
  }
  const momentObj=toDate(values.data['Дата']);
  setmenudataw('https://api.cemon.ru/menudata/menuzadatu?id='+selectpisheblok+'&n='+Moment(momentObj).format("YYYY,MM,DD"));
  
}

const handlePopupHidden = useCallback(() => {
  setmenuVisible(false);
  setmenudataw();
}, [menuVisible]);




const rendermenuwin = useCallback(() => {
  return(
  <div style={{height:'80%'}}>
  <DataGrid
    dataSource={menudataw}
    showBorders={true}
    width='100%'
    allowColumnResizing={true}
    style={{ paddingTop: 0, paddingBottom: 0}}
    showColumnLines={true}
    wordWrapEnabled={true}

   
  >
    <LoadPanel
        height={100}
        width={200}
        indicatorSrc={require('./load.gif')}

    />
  </DataGrid>
  <Paging defaultPageSize={100} />
  
  
  
  
  </div>
  )
}, [menudataw]);



const handlePopupHiddensab = useCallback(() => {
  setmenuVisiblesab(false);
}, []);

const clickmenusab = (values)=>{
  setmenutitlesab(values.data['Дата']);
  setmenuVisiblesab(true);
  const toDate = (dateStr) => {
    const [day, month, year] = dateStr.split(".")
    return new Date(year, month - 1, day)
  }
  const momentObj=toDate(values.data['Дата']);
  setmenudatawsab('https://api.cemon.ru/sopostovlenie/sab?n='+values.data['СсылкаНаТМСсылка']+'&k='+values.data['НомерДняВМеню']);
  setmenudatawsabb('https://api.cemon.ru/menudata/menuzadatu?id='+selectpisheblok+'&n='+Moment(momentObj).format("YYYY,MM,DD"));
  setmenudatawsabbb(values.data['Замечания']);
  
}

const rendermenuwinsab = useCallback(() => {
  return(
<div>

<div className="tables">
    <div className="column">
    <div className={'title-table'}><b>ТИПОВОЕ МЕНЮ</b></div>
    <DataGrid
    dataSource={menudatawsab}
    showBorders={true}
    width='100%'
    allowColumnResizing={true}
    style={{ paddingTop: 0, paddingBottom: 0}}
    columnAutoWidth={true}
    columnHidingEnabled={true}
    showColumnLines={true}
    //dataRowRender={DataRow}
    hoverStateEnabled={true}
    onExporting={onExporting}
    wordWrapEnabled={true}
    
   
  >
    <ColumnChooser enabled={true} mode="select" />
  <Export enabled={true} />
  <Paging defaultPageSize={15} />
  <Pager showPageSizeSelector={false} showInfo={true} />
      <LoadPanel
          height={100}
          width={200}
          indicatorSrc={require('./load.gif')}

      />
  </DataGrid>
  
    </div>
    <div className="column">
    <div className={'title-table'}><b>ФАКТИЧЕСКОЕ МЕНЮ</b></div>
    <DataGrid
    dataSource={menudatawsabb}
    showBorders={true}
    width='100%'
    allowColumnResizing={true}
    style={{ paddingTop: 0, paddingBottom: 0}}
    columnAutoWidth={true}
    columnHidingEnabled={true}
    showColumnLines={true}
    //dataRowRender={DataRow}
    hoverStateEnabled={true}
    onExporting={onExporting}
    wordWrapEnabled={true}
    
   
  >
    <Column dataField="ПриемПищиНаименование"/>
    <Column dataField="Раздел" />
    <Column dataField="Блюдо" />
    <Column dataField="Выход" />
    <ColumnChooser enabled={true} mode="select" />
  <Export enabled={true} />
  <Paging defaultPageSize={15} />
  <Pager showPageSizeSelector={false} showInfo={true} />
      <LoadPanel
          height={100}
          width={200}
          indicatorSrc={require('./load.gif')}

      />
  </DataGrid>
      
    </div>
    
    </div>
    <div className={'dx-card block'}>
    {menudatawsabbb}
    </div>
  </div>
  )
}, [menudatawsab,menudatawsabb]);

const pickerRef = useRef()



const renderTitle = () => {
  return (
    <div>
      <div className={'title-table'} style={{height:'40px'}}>МЕНЮ ПИЩЕБЛОКА ЗА {menutitle}</div>
      <Button icon="close" className={'dx-icon-closes'} onClick={handlePopupHidden}/>
      </div>
  );
}
//-----------------Дополнительная информация о пищеблоке---------------------
const [menuinfoVisible, setmenuinfoVisible] = useState(false);
const [dadatainfo, setdadatainfo] = useState([]);

function getinn() {
  return fetch('https://api.cemon.ru/inn/?id='+selectpisheblok)
  .then((response) => response.json())
  .then((responseJson) => {
  setinn( responseJson[0].ИНН);
  })
  .catch((error) => {
    
  });
}


function Mappokaz(){
  if (coordina){
        return  (
                  <div>
          <YMaps>
                  <Map width='100%' defaultState={{center: coordina,zoom: 15, controls: ["zoomControl", "fullscreenControl"],}} modules={["control.ZoomControl", "control.FullscreenControl"]} >
                  <Placemark defaultGeometry={coordina} />
                  </Map>
          </YMaps>
</div>

  )} else {

return (<div>Нет</div>)
  }
}

const clickmenuinfo = () => {
  setmenuinfoVisible(true);
  getdadaApi();

   }

   const handlePopupHiddeninfo = () => {
    setmenuinfoVisible(false);
  }


  //---------Вывод модального окна по основной информации-------------
  
  

  function getdadaApi() {
    fetch('https://api.cemon.ru/dadata?inn='+inn)
      .then((response) => response.json())
      .then((responseJson) => {
      setdadatainfo(responseJson);
    const a = [];
    a[0] = responseJson[9].Значение;
    a[1] = responseJson[10].Значение
    setcoordina(a);
    })
    .catch((error) => {});

  }
  
  

    



  const rendermenuwininfo =() => {
    return(
    <div> 
    <DataGrid
    ref={pickerRef}
    dataSource={dadatainfo}
    showBorders={true}
    width='100%'
    allowColumnResizing={true}
    style={{ paddingTop: 0, paddingBottom: 0}}
    showColumnLines={true}
    wordWrapEnabled={true}
  >
      <LoadPanel
          height={100}
          width={200}
          indicatorSrc={require('./load.gif')}

      />
    </DataGrid>
 
 
<div>
<Mappokaz />
</div>
    </div>  
  )
  }

//----------------------Проверка меню (Общая)---------------------

      //-------------------Проверка выхода-------------------
            const provervihoda = (priem, vihod)=>{
              const error=<div className='examplethree'>{vihod}</div>;
              if (priem==='Завтрак'&& vihod<500){return (error)}
              else if (priem==='Обед'&& vihod<700) {return (error)}
              else if (priem==='Завтрак 2'&& vihod<200) {return (error)}
              else if (priem==='Полдник'&& vihod<300) {return (error)}
              else if (priem==='Ужин'&& vihod<500) {return (error)}
              else if (priem==='Ужин 2'&& vihod<200) {return (error)}
              else {return (<div>{vihod}</div>)}
            }

            //------------------Проверка цены---------------------------
            const provercena = (cen)=>{
              const error=<div className='examplethree'>{cen}</div>;
              if (cen<1){return (error)}
              else {return (<div>{cen}</div>)}
            }


            //-------------------Проверка калорийности-------------------
            const proverkalor = (priem, kalor)=>{

              const error=<div className='examplethree'>{kalor}</div>;
              if (priem==='Завтрак'&& kalor<470){return (error)}
              else if (priem==='Обед'&& kalor<670) {return (error)}

              else {return (<div>{kalor}</div>)}
            }

//--------------Вывод ячеек-------------------------------------------------------

          //------------Выход-------------
          const Obshvihod = (cellData: DataGridTypes.ColumnCellTemplateData) => ( 
            <div>{provervihoda(cellData.data.ПриемПищиНаименование,cellData.data.Выход)}</div>
          )
          //------------Кол-во Ошибок-------------------
          const Cena=(cellData: DataGridTypes.ColumnCellTemplateData) => ( 
            <div>{provercena(cellData.data.Цена)}</div>
            )

          //------------Калорийность------------
          const Calorinost = (cellData: DataGridTypes.ColumnCellTemplateData) => ( 
            <div>{proverkalor(cellData.data.ПриемПищиНаименование,cellData.data.Калорийность)}</div>
          )

          //------------Кол-во Ошибок-------------------
          const Oshibki=(cellData: DataGridTypes.ColumnCellTemplateData) => ( 
          <div className='examplethree'>{cellData.data.ЧислоОшибок}</div>
          )


          const lanerror =(e:DataErrorOccurredEvent)=>{
              e.error.message="Ошибка загрузки данных";
      }

//----------------Вывод динамики размещения меню ------------------------

//----Дата---------------------------------------------------------------
const formatDate = new Intl.DateTimeFormat('ru').format;
const dinamikdate=(cellData: DataGridTypes.ColumnCellTemplateData) => {
  if (cellData.data.ПищеблокФункционировал === "да"){
    
  return(
  <div className='vihodnoi'>{formatDate(new Date(cellData.data.Дата))+' (выходной по КП*)'}</div>
  )
}
else {
  return(
    <div>{formatDate(new Date(cellData.data.Дата))+' ('+cellData.data.НомерДняВМеню+')'}</div> 
  )
}
}

//----Файл скачан---------------------------------------------------------------

const dinamikfile=(cellData: DataGridTypes.ColumnCellTemplateData) => {
  if ((cellData.data.ПищеблокФункционировал === "")&&(cellData.data.Файл === "да")) {return(<div>{cellData.data.Файл}</div>)}
  else if ((cellData.data.ПищеблокФункционировал === "да")&&(cellData.data.Файл === "да") ){return(<abbr title="Выгрузка в нерабочий день пищеблока. Проверьте календарь питания"><div className='examplethree'>{cellData.data.Файл}</div></abbr>)}
  else if ((cellData.data.ПищеблокФункционировал === "")&&(cellData.data.Файл === "нет") ){return(<div className='examplethree'>{cellData.data.Файл}</div>)}
  else if ((cellData.data.ПищеблокФункционировал === "да")&&(cellData.data.Файл === "нет")) {return(<div className='vihodnoi'>{cellData.data.Файл}</div>)}
                                                                      }
//----Обработан---------------------------------------------------------------
const dinamikobr=(cellData: DataGridTypes.ColumnCellTemplateData) => {
  if ((cellData.data.ПищеблокФункционировал === "")&&(cellData.data.Меню === "да")) {return(<div>{cellData.data.Меню}</div>)}
  else if ((cellData.data.ПищеблокФункционировал === "да")&&(cellData.data.Меню === "да") ){return(<abbr title="Выгрузка в нерабочий день пищеблока. Проверьте календарь питания"><div className='examplethree'>{cellData.data.Меню}</div></abbr>)}
  else if ((cellData.data.ПищеблокФункционировал === "")&&(cellData.data.Меню === "нет") ){return(<abbr title="Меню не обработано системой"><div className='examplethree'>{cellData.data.Меню}</div></abbr>)}
  else if ((cellData.data.ПищеблокФункционировал === "да")&&(cellData.data.Меню === "нет")) {return(<div className='vihodnoi'>{cellData.data.Меню}</div>)}

                                                                      }
//----Ошибок в меню---------------------------------------------------------------
const dinamikoshib=(cellData: DataGridTypes.ColumnCellTemplateData) => {
  if (cellData.data.ЧислоОшибок > 0) {return(<div className='examplethree'>{cellData.data.ЧислоОшибок}</div>)}
  else {return(<div>{cellData.data.ЧислоОшибок}</div>)}
}
//----% Соблюдения---------------------------------------------------------------
const dinamiksoblud=(cellData: DataGridTypes.ColumnCellTemplateData) => {
  if ((cellData.data.ПроцентСоблюдения < 90)&&(cellData.data.ПроцентСоблюдения !== "")) {return(<abbr title="Низкий % соблюдения типового меню. Подробная информация в таблице СОБЛЮДЕНИЕ ТИПОВОГО МЕНЮ"><div className='examplethree'>{cellData.data.ПроцентСоблюдения+'%'}</div></abbr>)}
  else if (cellData.data.ПроцентСоблюдения === "") {return(<div>{cellData.data.ПроцентСоблюдения}</div>)}
  else {return(<div>{cellData.data.ПроцентСоблюдения+'%'}</div>)}
}

const Dinamikinfo = useCallback(() =>{
  return(
    <div>
         
<DataGrid
    dataSource={menuinfo}
    showBorders={true}
    width='100%'
    allowColumnResizing={true}
    showColumnLines={true}
    wordWrapEnabled={true}
    //hoverStateEnabled={true}
    //hoverStateEnabled={true}
    onDataErrorOccurred={lanerror}
    columnHidingEnabled={false}
    onExporting={onExporting}
  >
  
  <Column dataField="Дата" caption="Дата и номер дня в КП* и ТМ**" dataType="date" defaultSortOrder="desc" minWidth={60} fixed={true} cellRender={dinamikdate} />
  
    <Column dataField="Файл" caption="Файл скачан" minWidth={50} cellRender={dinamikfile}/>
    <Column dataField="Меню" caption="Файл обработан" minWidth={50} cellRender={dinamikobr}/>
    <Column dataField="ЧислоОшибок" caption="Кол-во ошибок по СанПин" cellRender={dinamikoshib} minWidth={50}/>
    <Column dataField="ПроцентСоблюдения" caption="% соблюдения ТМ" cellRender={dinamiksoblud} minWidth={50}/>
    <Export enabled={true} />
  <LoadPanel
      height={100}
      width={200}
      indicatorSrc={require('./load.gif')}

  />
    </DataGrid>
    <div>*КП - календарь питания. Файл с имененем kpXXXX.xlsx, где XXXX - год (Например kp2024.xlsx). Располагается в папке food образовательной организации. <b>При отсутствии КП все дни считаются выходными.</b></div>
    <div>**ТМ - типовое меню. Файл с именем tmXXXX-sm.xlsx, где XXXX - год (Например tm2024-sm.xlsx). Располагается в папке food образовательной организации</div>
    
              
</div>
  )
},[menuinfo])


//---------------Модальное окно заявки на дочтение меню------------------------НАЧАЛО
 const openwinzapros =()=>{
   setmodzaprosvid(true);
   getlistzapros(selectpisheblok);

 }


const hideInfoModalWindowsMenu = useCallback(() => {
  setmodzaprosvid(false);
}, []);



const [modzaprosvid, setmodzaprosvid] = useState(false)
const [listzapros, setlistzapros] = useState(false)


  function getlistzapros(id) {
    return fetch("https://api.cemon.ru/perezaprosmenu/list/?id="+id)
        .then((response) => response.json())
        .then((responseJson) => {
          setlistzapros(responseJson)
        })
        .catch((error) => {

        });
  }

  function getmenuinfo(selectpisheblok,selectstart,selectend) {
    return fetch('https://api.cemon.ru/menustat?id='+selectpisheblok+'&n='+selectstart+'&k='+selectend)
        .then((response) => response.json())
        .then((responseJson) => {
          setmenuinfo(responseJson)
        })
        .catch((error) => {

        });
  }





  const [pinzapros, setpinzapros] = useState(false)
  const getpin = async (id) => {
    const response = await fetch(
        "https://api.cemon.ru/pinchange/?id="+id
    ).then((response) => response.json());
    setpinzapros(response.pin);

  };

  const [guidzapros, setguidzapros] = useState(false)

  function getguid(id) {
    return fetch("https://api.cemon.ru/perezaprosmenu/guid/?id="+id)
        .then((response) => response.json())
        .then((responseJson) => {
          setguidzapros(responseJson[0].Ref_Key)
        })
        .catch((error) => {

        });
  }






  const [rezultperezapros, setrezultperezapros] = useState('Отправка запроса...')
  const [resultperezaprosvisible, setresultperezaprosvisible] = useState(false);


  const hideInfoperezapros = useCallback(() => {
    getlistzapros(selectpisheblok);
    setresultperezaprosvisible(false);
  }, [selectpisheblok]);

  function setperezapros(id,n,k,pin) {
    return fetch("https://api.cemon.ru/perezaprosmenu?id="+id+"&dates="+n+"&datepo="+k+"&pin="+pin)
        .then((response) => response.json())
        .then((responseJson) => {
          setrezultperezapros(responseJson);
        })
        .catch((error) => {

        });
  }



//Вывод поле с вводом пинкода
  const Pincode = useCallback(() =>{
    if (pinzapros===1){
    return(
        <center><div>Внимание, у данного пищеблока установлен пин код. Введите его :
          <TextBox
              style={{width: '100px', display: 'inline-block', marginRight: '10px'}}
              defaultValue=""
              placeholder='Пин код'
              onValueChanged={valuechangepin}
              value={pinpinkode}
              valueChangeEvent="keyup"
          />
          Если Вы не знаете пин код, обратитесь к региональному отвественному за питание


        </div></center>
          )}},[pinzapros])

  const otpravdata = () => {
    setrezultperezapros('Отправка запроса');
    setperezapros(guidzapros,selectstart,selectend,pinpinkode);
    setresultperezaprosvisible(true);
  }

  const [pinpinkode, setpinpinkode] = useState();

  const [listpr, setlistpr] = useState();


  const valuechangepin = useCallback(({ value }) => {
    setpinpinkode(value);
  }, []);



//---------------Модальное окно заявки на дочтение меню------------------------КОНЕЦ


//------------------------------------------------------------------------------------------------------------------------------------------------------------
  

  //---------------- Блок основного вывода --------------------------------------------------------------------------------------------------------------------
  if (!status){
    return (
        <div className={'content-block dx-data-row2'}>
          <center><font size={15}>Сервер недоступен...</font></center>
        </div>
  )
  }
  else {
  return (
      <div>

        <div className={'content-block dx-data-row2'}>

        </div>


        <div className={'content-block dx-data-row2'}>
          <h2>Анализ ежедневного меню пищеблока <i>{selectpisheblok}</i></h2>
          <div>
            <TextBox
                style={{width: '200px', display: 'inline-block', marginRight: '10px'}}
                defaultValue=""
                placeholder='Поиск по коду'
                onValueChanged={valueChangedidpoisk}

                valueChangeEvent="keyup"
            />
            <Button
                width={100}
                text="Найти"
                type="default"
                stylingMode="contained"
                onClick={() => PoiskPoid()}
                icon="search"


            />
            <button id="link1" text="?" style={{verticalAlign: 'top', fontSize: '8px'}}>?</button>


          </div>

        </div>

        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>

          <div className={'title-table'}><b>ВЫБОР ПИЩЕБЛОКА</b></div>

          <div style={{width: '100%'}}>

            <Lookup
                id="Region"
                label="Регион"
                labelMode='static'
                stylingMode='outlined'
                dataSource={listregion}
                displayExpr="label"
                valueExpr="value"
                searchEnabled={true}
                placeholder="Выбор региона"
                onValueChanged={ChangeRegion}
                value={selectregion}
                //selectedItem={selectregion}
                onDataErrorOccurred={lanerror}
            />
            <Lookup
                id="Pisheblok"
                label="Пищеблок"
                labelMode='static'
                stylingMode='outlined'
                dataSource={groupedData}
                displayExpr="label"
                valueExpr="value"
                searchEnabled={true}
                placeholder="Выбор пищеблока"
                onValueChanged={ChangePisheblok}
                value={selectpisheblok}
                //selectedItem={selectpisheblok} 
                grouped={true}
                onDataErrorOccurred={lanerror}

            />


          </div>

          <Popover
              target="#link1"
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="top"
              width={300}
              showTitle={true}
              title="Код пищеблока"
          >
            Целое число для обозначения пищеблока. У каждого блока индивидуальный. Узнать код можно на странице анализа
            меню пищеблока. Необходим для быстрого поиска пищеблока и обращения в техническую поддержку. Пример кода:
            "12311"
          </Popover>

          <Popup
              visible={popupVisible}
              onHiding={hideInfo}
              dragEnabled={false}
              hideOnOutsideClick={true}
              showCloseButton={true}
              showTitle={true}
              title="Ошибка"
              container=".dx-viewport"
              width={300}
              height={280}>

            <div style={{margin: '10px'}}>
              <h4>
                <center>Пищеблок c кодом {iddlapoiska} не найден</center>
              </h4>
            </div>
          </Popup>


          <Popup
              visible={resultperezaprosvisible}
              onHiding={hideInfoperezapros}
              dragEnabled={true}
              hideOnOutsideClick={false}
              showCloseButton={true}
              showTitle={true}
              title="Результат запроса"
              container=".dx-viewport"
              width={300}
              height={280}>

            <div style={{margin: '10px'}}>

              <center>{rezultperezapros}</center>

            </div>
          </Popup>


        </div>
        <div className="right-side">
          <SelectBox
              items={getgod}
              width={128}
              label="учебный год"
              labelMode='static'
              stylingMode='underlined'
              value={god}
              displayExpr="label"
              valueExpr="value"
              onValueChanged={setinggod}

          />
        </div>
        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible', height: '300px'}}>
          <div className={'title-table'}><b>ПЕРИОД ОТЧЕТА</b></div>
          <div style={{margin: '5px auto', display: 'inline-block', verticalalign: 'middle'}}>

            <div style={{float: 'left', margin: '6px auto'}}>ПЕРИОД:&ensp;</div>
            <DateBox
                onValueChanged={selectPeriodn}
                value={range[0]}
                labelMode='static'
                style={{float: 'left', width: '100px', class: 'currency'}}
                stylingMode='underlined'
            />

            <div style={{float: 'left', margin: '6px auto'}}>- &ensp;</div>
            <DateBox
                onValueChanged={selectPeriodk}
                value={range[1]}
                labelMode='static'
                style={{float: 'left', width: '100px'}}
                stylingMode='underlined'
            />

          </div>
          <div>

            <RangeSelector
                id="range-selector"
                onValueChanged={selectPeriod}
                value={range}

            >
              <Margin top={3}/>
              <Scale
                  startValue={startValue}
                  endValue={endValue}

                  tickInterval="day"

              >

              </Scale>
              <SliderMarker format="monthAndDay"/>
            </RangeSelector>

          </div>


        </div>


        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>ОСНОВНАЯ ИНФОРМАЦИЯ</b></div>

          <div className={'zayavka'}>
            <Button
                //width={100}
                text="Редактировать ссылку пищеблока"
                type="success"
                stylingMode="outlined"

                icon="xlsxfile"
            /></div>

          <DataGrid
              dataSource={linkdata}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              showColumnLines={true}
              wordWrapEnabled={true}
              // onRowClick={clickmenuinfo}
              // hoverStateEnabled={true}
              onDataErrorOccurred={lanerror}

          >
            <Column dataField="Показатель" width={130} height='100px'/>
            <Column dataField="Значение"/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>
          <p onClick={clickmenuinfo} className={'pointlink'}>Подробнее</p>
          <Popup
              width={'90%'}
              height={'95%'}
              dragEnabled={true}
              hideOnOutsideClick={false}
              visible={menuinfoVisible}
              onHiding={handlePopupHiddeninfo}
              contentRender={rendermenuwininfo}
              showCloseButton={true}
              resizeEnabled={true}

          />


        </div>


        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>ДИНАМИКА РАЗМЕЩЕНИЯ МЕНЮ</b></div>


          <div className={'zayavka'}>
            <Button
                //width={100}
                text="Дочитать меню"
                type="success"
                stylingMode="outlined"
                onClick={openwinzapros}
                icon="xlsxfile"
            /></div>


          <Dinamikinfo/>


        </div>


        <div className={'content-block dx-card responsive-paddings'}>
          <div className={'title-table'}><b>МЕНЮ ПИЩЕБЛОКА</b></div>
          <DataGrid

              dataSource={menudata}
              showBorders={true}
              width='100%'
              // height={'400px'}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              hoverStateEnabled={true}
              onExporting={onExporting}
              onRowClick={clickmenu}
              //repaintChangesOnly={true}
              onDataErrorOccurred={lanerror}

          >

            <Column dataField="Дата"/>
            <Column dataField="ПриемПищиНаименование" caption="Приём пищи"/>
            <Column dataField="Выход" cellRender={Obshvihod} hidingPriority={0}/>
            <Column dataField="Цена" cellRender={Cena} hidingPriority={1}/>
            <Column dataField="Калорийность" cellRender={Calorinost} hidingPriority={2}/>
            <Column dataField="Белки" hidingPriority={3}/>
            <Column dataField="Жиры" hidingPriority={4}/>
            <Column dataField="Углеводы" hidingPriority={5}/>
            <Column dataField="ЧислоОшибок" caption="Ошибок" cellRender={Oshibki}/>
            <Export enabled={true}/>
            <Paging defaultPageSize={10}/>
            <Pager showPageSizeSelector={false} showInfo={true}/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>


        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>ОШИБКИ В МЕНЮ</b></div>
          <DataGrid
              dataSource={linkdataerror}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              //dataRowRender={DataRow}
              hoverStateEnabled={true}
              onExporting={onExporting}
              onDataErrorOccurred={lanerror}
          >
            <Column dataField="Дата" fixed={true}/>
            <Column dataField="ПриемПищиНаименование" hidingPriority={9}/>
            <Column dataField="ПроверкаПредставление"/>
            <Column dataField="Примечание" hidingPriority={10}/>
            <ColumnChooser enabled={true} mode="select"/>
            <Export enabled={true}/>
            <Paging defaultPageSize={10}/>
            <Pager showPageSizeSelector={true} showInfo={true}/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>

        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>СОБЛЮДЕНИЕ ТИПОВОГО МЕНЮ</b></div>
          <DataGrid

              dataSource={sobludenietip}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              //dataRowRender={DataRow}
              hoverStateEnabled={true}
              onExporting={onExporting}
              wordWrapEnabled={true}
              onRowClick={clickmenusab}
              onDataErrorOccurred={lanerror}
          >

            <HeaderFilter visible={true}>
              <Search enabled={true}/>
            </HeaderFilter>
            <Column dataField="Дата"/>
            <Column dataField="НомерДняВМеню"/>
            <Column dataField="ПроцентСоблюдения"
                    format="percent"
            />
            <Column dataField="Замечания"/>
            <Column dataField="СсылкаНаТМСсылка"/>
            <ColumnChooser enabled={true} mode="select"/>
            <Export enabled={true}/>
            <Paging defaultPageSize={10}/>
            <Pager showPageSizeSelector={false} showInfo={true}/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>

        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>АНКЕТА ПО РЕЙТИНГУ РОДИТЕЛЬСКОГО КОНТРОЛЯ</b></div>
          <DataGrid
              dataSource={findex}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              //dataRowRender={DataRow}
              hoverStateEnabled={true}
              onExporting={onExporting}
              wordWrapEnabled={true}
              onDataErrorOccurred={lanerror}
          >
            <HeaderFilter visible={true}>
              <Search enabled={true}/>
            </HeaderFilter>
            <Column dataField="Дата"/>
            <Column dataField="СвойствоНаименование"/>
            <Column dataField="ЗначениеСвойства"/>

            <ColumnChooser enabled={true} mode="select"/>
            <Export enabled={true}/>
            <Paging defaultPageSize={20}/>
            <Pager showPageSizeSelector={true} showInfo={true}/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>

        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>ОЦЕНКИ В ПРИЛОЖЕНИИ "ОЦЕНИ ПИТАНИЕ"</b></div>
          <DataGrid
              dataSource={ocenki}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              hoverStateEnabled={true}
              onExporting={onExporting}
              onDataErrorOccurred={lanerror}
          >
            <Column dataField="Дата" dataType="date" defaultSortOrder="desc"/>
            <Column dataField="КолвоОценок" caption="Респондентов"/>
            <Column dataField="Оценка" caption="Средняя оценка меню" dataType="number" format="0.#"/>

            <ColumnChooser enabled={true} mode="select"/>
            <Export enabled={true}/>
            <Paging defaultPageSize={10}/>

            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>


        <div className={'content-block dx-card responsive-paddings'} style={{overflow: 'visible'}}>
          <div className={'title-table'}><b>ЛОГ АВТОМАТИЗИРОВАННЫХ ЗАПРОСОВ</b></div>
          <DataGrid
              dataSource={chtenielog}
              showBorders={true}
              width='100%'
              allowColumnResizing={true}
              style={{paddingTop: 0, paddingBottom: 0}}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              showColumnLines={true}
              //dataRowRender={DataRow}
              hoverStateEnabled={true}
              onExporting={onExporting}
              onDataErrorOccurred={lanerror}
          >
            <Column dataField="Дата" dataType="date" defaultSortOrder="desc"/>
            <Column dataField="ВыполнениеНачато"/>
            <Column dataField="ВремяЗавершения"/>
            <Column dataField="БылаОшибка"/>
            <Column dataField="КодОшибки"/>
            <ColumnChooser enabled={true} mode="select"/>
            <Export enabled={true}/>
            <Paging defaultPageSize={10}/>
            <LoadPanel
                height={100}
                width={200}
                indicatorSrc={require('./load.gif')}

            />
          </DataGrid>

        </div>
        <div>
          <Popup
              width={'90%'}
              height={'95%'}
              showTitle={true}
              //title={menutitle}
              titleRender={renderTitle}
              dragEnabled={true}
              hideOnOutsideClick={true}
              visible={menuVisible}
              onHiding={handlePopupHidden}
              contentRender={rendermenuwin}
              showCloseButton={true}
          />

        </div>

        <div>
          <Popup
              width={'90%'}
              height={'95%'}
              showTitle={true}
              //titleRender ={rentitle}
              title={menutitlesab + ' Соответствие фактического и типового меню'}
              dragEnabled={true}
              hideOnOutsideClick={false}
              visible={menuVisiblesab}
              onHiding={handlePopupHiddensab}
              contentRender={rendermenuwinsab}
              showCloseButton={true}
              resizeEnabled={true}

          />
        </div>

        <div>
          <div>
            <Popup
                width={'90%'}
                height={'90%'}
                showTitle={true}
                //titleRender ={rentitle}
                title={'Заявка на дочтение ежедневного меню'}
                dragEnabled={true}
                hideOnOutsideClick={false}
                visible={modzaprosvid}
                onHiding={hideInfoModalWindowsMenu}
                // contentRender={rendermodzaprosvid}
                showCloseButton={true}
                resizeEnabled={true}

            >
              <div>

                <div>
                  <div className={'title-table'}><b>ВАЖНАЯ ИНФОРМАЦИЯ</b></div>
                  <div className={'content-block dx-card responsive-paddings info'}>

                    <i
                        className="dx-icon dx-icon-info"></i>Ограничение
                    запроса от одной образовательной организации - 1 раз в сутки. Период обработки запроса - 1 час и
                    более. <u><b>Уже
                    загруженные меню (присутствующие в базе ФЦМПО) не перезаписываются</b></u>. Чтение происходит
                    только по новым
                    меню. При необходимости удаления старого меню и дочтения нового – обратитесь к региональному
                    отвественному за питание.
                  </div>
                  <br></br>
                  <div className={'title-table'}><b>ЗАЯВКА НА ДОЧТЕНИЕ МЕНЮ В ПЕРИОД
                    С {selectstart.replace(pattern, ".")} ПО {selectend.replace(pattern, ".")}</b></div>
                  <div className={'content-block dx-card responsive-paddings'}>

                    <Pincode/>
                    <center><Button

                        text="ОТПРАВИТЬ ЗАЯВКУ НА ДОЧТЕНИЕ"
                        type="success"
                        stylingMode="outlined"
                        onClick={otpravdata}
                        icon="xlsxfile"

                        //icon="search"


                    />
                    </center>
                    <br></br>

                    <div className={'title-table'}><b>Результат предварительной проверки доступности файлов на сайте
                      пищеблока</b></div>
                    <DataGrid
                        dataSource={listpr}
                        showBorders={true}
                        width='100%'
                        allowColumnResizing={true}
                        showColumnLines={true}
                        wordWrapEnabled={true}
                        columnHidingEnabled={false}
                        onExporting={onExporting}
                    >
                      <Export enabled={true}/>
                      <Paging defaultPageSize={5}/>
                      <Pager
                          visible={true}

                          showInfo={true}
                          showNavigationButtons={false}/>
                      <LoadPanel
                          height={100}
                          width={200}
                          indicatorSrc={require('./load.gif')}

                      />
                    </DataGrid>


                  </div>


                  <div className={'title-table'}><b>СПИСОК ЗАПРОСОВ НА ДОЧТЕНИЕ ЕЖЕДНЕВНОГО МЕНЮ</b></div>
                  <DataGrid
                      dataSource={listzapros}
                      showBorders={true}
                      width='100%'
                      allowColumnResizing={true}
                      showColumnLines={true}
                      wordWrapEnabled={true}
                      //hoverStateEnabled={true}
                      //hoverStateEnabled={true}
                      //onDataErrorOccurred={lanerror}
                      columnHidingEnabled={false}
                      onExporting={onExporting}
                  >

                    <Paging defaultPageSize={5}/>
                    <Pager
                        visible={true}

                        showInfo={true}
                        showNavigationButtons={false}/>
                    <Export enabled={true}/>
                    <LoadPanel
                        height={100}
                        width={200}
                        indicatorSrc={require('./load.gif')}

                    />
                  </DataGrid>


                </div>

              </div>
            </Popup>
          </div>


        </div>


      </div>
  );
  }
}