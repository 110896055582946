import React, {useState} from 'react';
import './home.scss';
import Select from "react-select";
import DataGrid, {
    Paging, SearchPanel, HeaderFilter, FilterRow, DataGridTypes, Export, LoadPanel
} from 'devextreme-react/data-grid';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages, date} from 'devextreme/localization';
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";

export default function Home() {
    //Руссификация сообщений
    locale(sessionStorage.getItem('locale') || 'ru');
    loadMessages(ruMessages);
    const [data, setdata] = useState('https://api.cemon.ru/jsondata/all.json');
    console.clear();

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Выгрузка');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
            });
        });
    };


  return (
      <React.Fragment>
          <h2 className={'content-block'}>Справочник пищеблоков</h2>
          <div className={'content-block'}>
              <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>
                  <DataGrid id="gridContainer"
                            dataSource={data}
                            showBorders={true}
                            onExporting={onExporting}
                            wordWrapEnabled={true}>
                      <FilterRow visible={true} />
                      <SearchPanel visible={true}/>
                      <HeaderFilter visible={true} />
                      <Paging pageSize={10} />
                      <Export enabled={true} />
                      <LoadPanel
                          height={100}
                          width={200}
                          indicatorSrc={require('./load.gif')}

                      />
                  </DataGrid>
                  </div>
          </div>

                  <h2 className={'content-block'}>О мониторинге</h2>
                  <div className={'content-block'}>
                      <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>

                          Автоматизированный мониторинг организации горячего питания обучающихся включает:
                          <ul>
                              <li>средства наблюдения за меню фактического питания, опубликованных на официальных сайтах
                                  образовательных организаций;
                              </li>
                              <li>анализ меню с выявлением отклонений данных о потреблении (выход блюд, калорийность и
                                  основные
                                  пищевые вещества по приемам пищи) от нормативов потребления;
                              </li>
                              <li>мониторинг стоимости комплексов блюд по приемам пищи;</li>
                              <li>состава меню фактического питания;</li>
                              <li>исполнительской дисциплиной по публикации меню на официальных сайтах.</li>
                          </ul>
                          Вышеперечисленные
                          параметры контролируются с использованием централизованной базы данных, аналитических
                          отчетов для специалистов ФЦМПО и региональных органов управления образованием, а также
                          публичного интерфейса анализа меню на специализированном интернет-сайте
                          https://мониторингпитание.рф. В развитие находящейся в эксплуатации системы мониторинга по
                          вышеперечисленным параметрам в систему внесены:
                          <ul>
                              <li>средства для построения рейтинга</li>
                              <li>общественного контроля;</li>
                              <li>визуализации результатов массовых социологических опросов по питанию;</li>
                              <li>средства взаимодействия со сторонними информационными ресурсами в части справочника
                                  пищеблоков
                                  и
                                  школ
                              </li>
                              <li>сформулированы общие требования к проектируемым средствам
                                  визуализации и специализированным рабочим местам специалистов региональных управлений
                                  образования и экспертов ФЦМПО.
                              </li>
                          </ul>
                          Примечание: иные вопросы, относящиеся к управлению системой школьного питания (т. к.
                          мониторинг формы организации питания в школе и соответствующих систем ценообразования;
                          численность питающихся; фактический товарооборот продуктов и его сопоставление со
                          среднесуточными нормативами по продуктовым группам; контроль питания не только младшей
                          возрастной категории; обращения граждан и результаты их рассмотрения и др.) выходят за рамки
                          существующей системы мониторинга питания обучающихся и могут быть предметом технического
                          задания для последующих стадий (версий) системы мониторинга.
                          Задача мониторинга питания обучающийся начальных классов РФ.
                          В части мониторинга меню фактического питания, публикуемых на сайтах школ, система включает:
                          <ul>
                              <li> регулярное считывание файлов меню с установленным шаблоном имени файла
                                  «гггг-мм-дд-sm.xlsx» в центральную базу данных;
                              </li>
                              <li> контроль считываемых файлов на соответствие формату бланка;</li>
                              <li> разбор скачанных файлов с помещение полученного в цифровом виде
                                  документа в информационной базе (вида «Меню фактическое»)
                              </li>
                          </ul>
                          Данные работы выполняются в центральной информационной базе ФЦМПО (наплатформе
                          «1С:Предприятие»), размещенной на интернет-сайте <a
                          href='https://cemon.ru'>https://cemon.ru</a>, в
                          автоматическом режиме, один раз в сутки.
                      </div>


                  </div>
      </React.Fragment>
)
}
